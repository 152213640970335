@import '../../style/variables.scss';

.header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    background-color: #fff;

    &__logo {
        cursor: pointer;
        padding: 10px;

        img {
            height: 40px;
        }
    }

    &__btns {
        display: flex;
        align-items: center;

        &-login {
            height: 35px;
            margin-right: 20px;
        }
    }

    &__globe {
        margin-right: 10px;
    }

    &__menu {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(4, auto);
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 600;

        &-item {
            cursor: pointer;
            text-transform: uppercase;
            transition: .2s color;

            &:hover {
                color: $main-color;
            }
        }
    }
}

@media (min-width: 691px) {
    .hamburger-react {
        display: none;
    }
}

@media (max-width: 690px) {
    .header {
        position: relative;
        padding: 0px 15px;
        z-index: 1;

        &__menu {
            position: absolute;
            padding: 30px;
            background-color: #fff;
            border-bottom: 1px solid #e8e8e8;
            grid-template-columns: repeat(1, auto);
            width: 100%;
            top: 60px;
            left: 0;
            transition: transform .3s;
            transform: scaleY(0%);
            transform-origin: center top;
            z-index: -1;

            &-active {
                transform: scaleY(100%);
            }
        }
    }
}
@import '../../style/variables.scss';

.App {
  max-width: 1240px;
  margin: 0 auto;
  line-height: 1.25;
}

.notifications {
  position: fixed;
  bottom: 20px;
  right: 20px;

  &__alert {
    box-shadow: 0px 0px 150px 0px rgba(0, 0, 0, 0.2);
  }
}

.title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.25rem; 
}

.subtitle {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem; 
}

.content {
  padding: 20px 30px;
}

.page-loading-spinner {
  width: 100%;
  height: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-label {
  display: block;
  margin-bottom: 8px;
}

.field {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9px;
  border: 1px solid $border-color;
  border-radius: 3px;
  background-color: $input-color;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@media (max-width: 540px) {
  .content {
    padding: 15px;
  }
}
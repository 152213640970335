@tailwind base;
@tailwind components;
@tailwind utilities;

@import './style/nullstyle.scss';
@import './style/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

* {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 10px;

    &:horizontal {
        display: none;
    }
  }

  &::-webkit-scrollbar-track {
      background-color: #f9f9f9;
      border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #e6e6e6;
      border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
      background-color: #cbcbcb;
  }
}

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error {
  display: block;
  margin-top: 8px;
  color: red;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.grecaptcha-badge {
  visibility: hidden;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}
